.about-us-container {
  font-family: Arial, sans-serif;
  color: #333;
  width: 100% !important;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  padding-top: 0;
}

.about-us-ceo {
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
  text-align: right;
  margin-bottom: 0;
}

span {
  color: #0a7273;
}

.about-us-content {
  display: flex;
  max-width: 1200px;
  width: 100%;
  gap: 2rem;
  align-items: flex-start;
}

.about-us-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: cover;
}

.about-us-text {
  flex: 1;
}

.about-us-title {
  margin-top: 0 ;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: black ;
  font-weight: bold;
}

.about-us-description {
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
}

.about-us-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* max-width: 1200px; */
  padding: 2rem 0;
  background-color: white;
  margin-top: 2rem;
  gap: 2rem;
}

.stat-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.stat-item h2 {
  font-size: 2.5rem;
  color: black;
  font-weight: bold;
  margin: 0;
}

.stat-item p {
  font-size: 1rem;
  margin-top: 0.5rem;
}

@media (min-width: 768px) {
  .about-us-stats {
    flex-direction: row;
    justify-content: space-around;
  }
}

@media (max-width: 768px) {
  .about-us-content {
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .about-us-image img {
    max-width: 100%;
    height: auto;
  }

  .about-us-title {
    font-size: 2rem;
  }

  .about-us-description {
    font-size: 0.9rem;
  }

  .about-us-stats {
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
  }

  .stat-item {
    flex: 1;
    text-align: center;
  }

  .stat-item h2 {
    font-size: 2rem;
  }

  .stat-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 360px), (max-width: 412px)  {
  .about-us-container {
    padding: 0rem 0rem;
  }

  .about-us-title {
    font-size: 1.6rem;
    text-align: center;
  }

  .about-us-description {
    font-size: 0.8rem;
    text-align: justify;
    margin-bottom: 0;
  }

  .about-us-stats {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
    padding:0 1rem;
  }

  .stat-item {
    margin-bottom: 1rem;
    text-align: center;
  }

  .stat-item h2 {
    font-size: 1.6rem;
  }

  .stat-item p {
    font-size: 0.8rem;
  }

  .about-us-image img {
    max-width: 90%;
  }
}
