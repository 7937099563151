/* Container Styling */
.custom-services-container {
    padding: 0 2rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  .custom-services-title {
    margin-top: 100px;
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 2.1rem;
    text-align: center;
  }
  
  .custom-services-title .custom-highlight {
    color: #107678;
    font-size: 2.5rem;
  }
  
  /* Grid Layout for 4 Cards in a Row */
  .custom-services-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 4 equal-width columns */
    gap: 1.5rem;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .custom-service-card {
    background-color: #ffffff;
    border: 2px solid #e0e0e0;
    border-radius: 15px;
    box-shadow: 0 4px 8px #2e3d3d;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .custom-service-icon {
    width: 180px;
    height: 180px;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .custom-service-card-orange {
    background-color: #ffdd8e;
    color: #333;
  }
  
  .custom-service-card-black {
    background-color: #333;
    color: #FFF;
  }
  
  .custom-service-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0rem;
  }
  
  .custom-service-description {
    font-size: 0.9rem;
    line-height: 1.6;
    text-align: left;
    display: inline;
  }
  
  .custom-button-container {
    margin-top: 10px;
  }
  
  .custom-service-button {
    background-color: #fda521;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
  }
  
  .custom-button-container a {
    text-decoration: none;
  }
  
  .custom-service-button:hover {
    background-color: #00796b;
  }
  
  /* Responsive Styles */
  
  /* For screens smaller than 768px */
  @media (max-width: 768px) {
    .custom-services-title {
      font-size: 2.3rem;
      margin-top: 120px;
    }
  
    .custom-services-grid {
      grid-template-columns: repeat(2, 1fr); /* Change to 2 columns */
    }
  
    .custom-service-card {
      width: 90%;
      padding: 1.2rem;
      margin: 0 auto;
    }
  
    .custom-service-icon {
      width: 150px;
      height: 150px;
      margin-bottom: 1rem;
    }
  
    .custom-service-title {
      font-size: 1.2rem;
    }
  
    .custom-service-description {
      font-size: 1rem;
    }
  }
  
  /* For screens smaller than 360px */
  @media (max-width: 360px), (max-width: 412px) {
  
    .custom-services-title {
        font-size: 2.5rem !important;
        margin-top: 100px;
      }
  
    .custom-services-grid {
      grid-template-columns: 1fr; /* Change to 1 column */
      gap: 1rem;
    }
  
    .custom-service-card {
      width: 90%;
      padding: 1rem;
      margin: 0 auto;
    }
  
    .custom-service-icon {
      width: 120px;
      height: 120px;
      margin-bottom: 0.8rem;
    }
  
    .custom-service-title {
      font-size: 1.3rem;
    }
  
    .custom-service-description {
      font-size: 0.9rem;
    }
  
    .custom-service-button {
      font-size: 12px;
    }
  }
  