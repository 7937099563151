.blog-carousel-wrapper {
  padding: 20px;
  background-color: #f9f9f9;
}

strong{
  color: black;
}

/* Full Blog Styles */
.full-blog {
  margin-top: 70px !important;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  line-height: 1.6;
}

.full-blog h1 {
  text-align: center;
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  color: #2980b9;
}

.full-blog h2 {
  color: #2980b9;
  margin-top: 30px;
  font-size: 20px;
  text-align: left;
  margin-bottom: 10px;
}

.blog-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 20px;
}

.blog-content {
  font-size: 1rem;
  color: #555;
  line-height: 1.8;
}

.blog-content p {
  margin: 10px 0;
}

.blog-content ul {
  margin: 10px 0 10px 20px;
  padding-left: 10px;
  list-style: disc;
}

.blog-content ul li {
  margin-bottom: 8px;
  color: #555;
}

.back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #FDA521;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.back-button:hover {
  background-color: #0a7273;
}

/* Responsive Design for 768px (Tablets) */
@media (max-width: 768px) {
  .full-blog {
    padding: 15px;
    margin-top: 30px;
  }

  .full-blog h1 {
    font-size: 28px;
    margin-top: 20px;
  }

  .full-blog h2 {
    font-size: 18px;
  }

  .blog-content {
    font-size: 0.95rem;
  }

  .back-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

/* Responsive Design for 360px (Mobile Devices) */
@media (max-width: 360px), (max-width: 412px) {
  .full-blog {
    padding: 10px;
    margin-top: 20px;
  }

  .full-blog h1 {
    font-size: 24px ;
    margin-top: 10px;
  }

  .full-blog h2 {
    font-size: 16px !important;
  }

  .blog-content {
    font-size: 0.9rem;
    line-height: 1.6;
  }

  .back-button {
    font-size: 0.85rem;
    padding: 8px 14px;
  }

  .blog-content ul {
    margin-left: 15px;
  }

  .blog-image {
    border-radius: 5px;
  }
}
