.technologies-section {
  text-align: center;
  padding: 50px 20px;
}

.technologies-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.technologies-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default: 4 icons per row */
  justify-content: center;
  width: 500px;
  margin: 0 auto;
}

.technology-card {
  text-align: center;
  padding: 15px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.technology-card:hover {
  background-color: #f0f0f0;
  transform: translateY(-5px);
}

.tech-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.technology-card h3 {
  font-size: 0.9rem;
  color: #333;
  margin: 0;
}

/* Responsive for tablets (768px and below) */
@media (max-width: 768px) {
  .technologies-grid {
    grid-template-columns: repeat(4, 1fr); /* 3 icons per row */
    width: 60%; /* Allow full-width grid */
  }
}

/* Responsive for small screens (360px and below) */
@media (max-width: 360px), (max-width: 412px) {
  .technologies-grid {
    grid-template-columns: repeat(4, 1fr); 
    width: 100%;
  }

  .technologies-section h2 {
    font-size: 2rem;
    margin-bottom: 30px;
    color: #333;
  }

  .technology-card {
    width: 55px; /* Slightly smaller cards for small screens */
    height: 80px;
  }

  .tech-icon {
    width: 40px; /* Adjust icon size */
    height: 40px;
  }

  .technology-card h3 {
    font-size: 0.8rem; /* Reduce font size */
  }
}
