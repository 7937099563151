.reviews-section {
    text-align: center;
    padding: 40px;
    background-color: white;
    overflow: hidden;
    position: relative;
}

.reviews-title {
    font-size: 24px;
    font-weight: bold;
    color: teal;
    margin-bottom: 10px;
}

.stars {
    font-size: 20px;
    color: gold;
    margin-bottom: 5px;
}

.reviews-subtitle {
    font-size: 18px;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
}

/* Slider container to enable infinite scrolling effect */
.reviews-slider {
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

/* Animating the reviews-container for sliding effect */
.reviews-container {
    display: flex;
    gap: 20px;
    flex-wrap: nowrap; 
    animation: slide 120s linear infinite;
}

/* Styling for individual review cards */
.review-card {
    width: 300px;
    background: aliceblue;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
    flex: 0 0 auto; /* Prevents wrapping */
}

.review-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.review-user {
    flex-grow: 1;
}

.review-name {
    font-weight: bold;
    font-size: 16px;
}

.review-date {
    font-size: 12px;
    color: gray;
}

.review-platform {
    font-size: 20px;
    color: blue;
    font-weight: bold;
}

.review-stars {
    margin: 10px 0;
    font-size: 16px;
    color: gold;
}

.review-text {
    font-size: 14px;
    color: gray;
    line-height: 1rem;
}

/* Sliding animation */
@keyframes slide {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
/* ✅ Responsive Design for Mobile (412px) and Tablet (768px) */
@media (max-width: 768px) {
    .reviews-container {
        animation: slide 120s linear infinite; /* Adjust speed for smaller screens */
        gap: 20px; /* Reduce space between cards */
    }

    .review-card {
        width: 250px; /* Slightly smaller cards */
        padding: 15px;
    }

    .review-name {
        font-size: 14px;
    }

    .review-text {
        font-size: 12px;
    }
}

@media (max-width: 412px) {
    .reviews-section {
        padding: 0px; /* Less padding */
    }

    .reviews-title {
        font-size: 20px; /* Reduce title size */
    }

    .reviews-container {
        gap: 15px;
        animation: slide 120s linear infinite;
    }

    .review-card {
        width: 200px; /* Make cards smaller for better fit */
        padding: 10px;
    }

    .review-name {
        font-size: 12px;
    }

    .review-text {
        font-size: 11px;
        line-height: 0.9rem;
    }
}