.social-media-container {
  width: 80%;
  margin: 70px auto 0 auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.social-media-container h1 {
  text-align: center;
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  color: #2980b9;
}

.social-media-container h2 {
  color: #2980b9;
  margin-top: 0px;
  font-size: 20px;
  margin-bottom: 15px;
  text-align: left;
}

.social-media-container p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: black;
  text-align: left;
}

.social-media-container ul {
  margin-left: 20px;
  font-size: 14px;
}

.social-media-container img {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 30px auto;
  border-radius: 8px;
}

.social-media-container ul li {
  margin-bottom: 8px;
}

.social-media-container h3 > span {
  font-size: 20px;
  margin-right: 8px;
}

.social-media-container h2:last-of-type {
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
}

.social-media-container .last p {
  font-size: 16px;
  text-align: center;
}

.social-media-container .last h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
}

.last {
  text-align: center; /* Centers the text and the button */
}

.quotation-button {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 20px;
  background-color: #FDA521; 
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.quotation-button:hover {
  background-color: #00796b;
  transform: scale(1.05);
}

@media (max-width: 360px) , (max-width: 412px){
  .social-media-container {
    padding: 8px;
    margin: 70px auto 0 auto;
    width: 90%;
  }

  .social-media-container h1 {
    font-size: 30px;
  }

  .social-media-container h2 {
    font-size: 20px;
  }

  .social-media-container p, .social-media-container ul {
    font-size: 14px;
  }

  .social-media-container img {
    max-width: 90%;
    margin-bottom: 0;
    max-width: 60%;
  }

  .social-media-container ul {
    margin-left: 0px;
  }

  .social-media-container button, .social-media-container a {
    font-size: 14px;
    padding: 6px 12px;
  }
}

/* @media (max-width: 480px) {
  .social-media-container {
    padding: 8px;
    margin: 70px auto 0 auto;
    width: 90%;
  }

  .social-media-container h1 {
    font-size: 30px;
  }

  .social-media-container h2 {
    font-size: 20px;
  }

  .social-media-container p, .social-media-container ul {
    font-size: 14px;
  }

  .social-media-container img {
    max-width: 90%;
    margin-bottom: 0;
    max-width: 65%;
  }

  .social-media-container ul {
    margin-left: 0px;
  }

  .social-media-container button, .social-media-container a {
    font-size: 14px;
    padding: 6px 12px;
  }
} */

@media (max-width: 768px) {
  .social-media-container {
    padding: 10px;
    width: 80%;
  }

  .social-media-container h1 {
    font-size: 26px;
  }

  .social-media-container h2 {
    font-size: 20px;
  }

  .social-media-container h3 {
    font-size: 16px;
  }

  .social-media-container p, .social-media-container ul {
    font-size: 15px;
  }

  .social-media-container img {
    max-width: 90%;
    margin-bottom: 0;
    max-width: 75%;
  }

  .social-media-container ul {
    margin-left: 20px;
  }

  .social-media-container button, .social-media-container a {
    font-size: 16px;
    padding: 8px 16px;
  }
}
