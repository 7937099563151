.testimonials-container {
  padding: 0 2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.testimonials-title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
}

.testimonials-title span {
  color: #0a7273;
  font-size: 2.5rem;
}

.testimonials-carousel {
  position: relative;
  overflow: hidden;
  max-width: 700px;
  margin: 0 auto;
}

.testimonials-cards {
  display: flex;
  justify-content: center; 
  transition: transform 0.3s ease;
  gap: 1rem; 
}

.testimonial-card {
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: 0 4px 8px #0a7273;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 300px; 
}

.company-name {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1rem;
}

.quote {
  font-size: 1rem;
  color: #555;
  margin-bottom: 1.5rem;
  line-height: 1.6;
  text-align: left;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.user-avatar {
  width: 40px;
  height: 40px;
  background-color: #d3d3d3;
  border-radius: 50%;
  overflow: hidden;
}

.user-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-weight: bold;
  font-size: 1rem;
  color: #333;
  text-align: start;
}

.user-position {
  font-size: 0.875rem;
  color: #777;
  text-align: start;
}

.pagination-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.dot {
  margin-top: 10px;
  width: 10px;
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 50%;
  display: inline-block;
}

.dot.active {
  background-color: #fda521;
}

.carousel-navigation {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.prev-btn, .next-btn {
  background-color: #0a7273;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 50%;
  transition: transform 0.3s ease, background-color 0.3s, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.prev-btn:hover, .next-btn:hover {
  background-color: #fda521;
  transform: scale(1.1);
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
}

.prev-btn:focus, .next-btn:focus {
  outline: none;
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.1);
}

.prev-btn:focus-visible, .next-btn:focus-visible {
  border: 2px solid #fda521;
  background-color: #0a7273;
}

/* Media Queries for Different Screen Sizes */
@media (max-width: 1024px) {
  .testimonials-cards {
    flex-direction: row;
    justify-content: center;
  }

  .testimonial-card {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .testimonials-cards {
    align-items: center;
  }

  .testimonial-card {
    width: 50%;
    padding: 1.5rem;
    height: 400px;
  }

  .quote {
    font-size: 0.95rem;
  }

  .user-name {
    font-size: 0.95rem;
  }

  .user-position {
    font-size: 0.8rem;
  }

  .prev-btn, .next-btn {
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }
}

@media (max-width: 360px), (max-width: 412px)  {
  .testimonials-title {
    font-size: 1.5rem;
  }
  
  .testimonials-title span {
    color: #0a7273;
    font-size: 2rem;
  }
  .testimonials-cards {
    flex-direction: column;
    align-items: center;
  }

  .testimonial-card {
    width: 190px;
    height: 370px;
    padding: 1rem;
  }

  .quote {
    font-size: 0.85rem;
  }

  .user-name {
    font-size: 0.85rem;
  }

  .user-position {
    font-size: 0.7rem;
  }

  .prev-btn, .next-btn {
    width: 35px;
    height: 35px;
    font-size: 1.2rem;
  }
}
