.nav-links .relative {
    position: relative;
}

.nav-links .relative div {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    color: #333;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    border-radius: 8px;
    transition: all 0.3s ease;
}

.nav-links .relative div:hover {
    transform: scale(1.05);
}

.nav-links .relative .absolute {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 240px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 10;
    animation: fadeIn 0.3s ease;
}

.nav-links .relative .absolute li {
    list-style: none;
    padding: 12px 10px 12px 10px;
    font-size: 15px;
    color: #444;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-bottom: 1px solid #f1f1f1;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.nav-links .relative .absolute li:last-child {
    border-bottom: none;
}

.nav-links .relative .absolute li:before {
    content: "•";
    font-size: 20px;
    color: #00796b;
    margin-right: 10px;
    display: inline-block;
}

.nav-links .relative .absolute li:hover {
    background-color: #f5f5f5;
    color: #00796b;
}

.nav-links .relative .absolute li:active {
    background-color: #80deea;
}

.nav-links .relative:hover .absolute {
    display: block;
}

@media (max-width: 768px) {
    .nav-links {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav-links .relative {
        width: 100%;
    }

    .nav-links .relative div {
        padding: 0;
        font-size: 14px;
    }

    .nav-links .relative .absolute {
        position: relative;
        width: 100%;
        box-shadow: none;
        border: none;
    }

    .nav-links .relative .absolute li {
        padding: 8px 12px;
        font-size: 14px;
    }

    .nav-links .relative .absolute li:before {
        font-size: 18px;
    }
}

@media (max-width: 360px) , (max-width: 412px){
    .nav-links .relative {
        position: relative;
    }

    .nav-links .relative div {
        font-size: 10px;
    }
    
    .nav-links .relative .absolute {
        margin: 0px;
        padding: 0;
        position: relative;
    }

    .nav-links .relative .absolute li {
        padding: 4px;
        font-size: 10px;
        line-height: 1.2;
    }

    .nav-links .relative .absolute li:before {
        font-size: 14px;
        margin-right: 4px;
    }
}