.website-development {
  width: 80%;
  margin: 70px auto 0 auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.website-development h1 {
  text-align: center;
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  color: #2980b9;
}

.website-development h2 {
  color: #2980b9;
  margin-top: 30px;
  font-size: 20px;
  text-align: left ;
  margin-bottom: 10px;
}

.website-development p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 10px;
}

.website-development ul {
  margin-left: 0px;
  list-style-type: disc;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 35px;
}

.website-development img {
  max-width: 70%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.website-development ul li {
  margin-bottom: 6px;
}

.features a {
  padding: 0 ;
  background-color: #fff ;
  color: blue ;
  text-decoration: underline ;
}

.website-development .contact-us h2:last-of-type {
  text-align: center ;
  font-size: 24px;
  font-weight: bold;
  margin-top: 50px ;
}

.website-development .contact-us p:last-of-type {
  text-align: center;
  font-size: 16px;
  color: #2980b9;
}

.website-development .contact-us p {
  text-align: center;
  font-size: 16px;
}
.contact-us {
  text-align: center; /* Centers the text and the button */
}

.quotation-button {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 20px;
  background-color: #FDA521; 
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.quotation-button:hover {
  background-color: #00796b;
  transform: scale(1.05);
}


@media (max-width: 768px) {
  .website-development {
    margin-top: 100px !important;
    width: 80%;
    margin: 40px auto;
    padding: 20px;
  }

  .website-development h1 {
    font-size: 35px;
    margin-top: 20px;
  }

  .website-development h2 {
    font-size: 25px;
    margin-top: 10px;
    margin-bottom: 8px;
  }

  .website-development p {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .website-development ul {
    font-size: 20px;
  }

  .website-development img {
    max-width: 90%;
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .website-development ul li {
    margin-bottom: 10px;
  }

  .website-development .contact-us h2:last-of-type {
    font-size: 22px;
    margin-top: 20px;
  }

  .website-development .contact-us p:last-of-type {
    font-size: 22px;
  }

  .website-development .contact-us p {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 360px), (max-width: 412px) {
  .website-development {
    width: 80%;
    margin-top: 80px !important;
    margin: 15px auto;
    padding: 10px;
  }

  .website-development h1 {
    font-size: 30px;
    margin-top: 10px;
  }

  .website-development h2 {
    font-size: 20px !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .website-development p {
    font-size: 12px;
    margin-bottom: 8px;
  }

  .website-development ul {
    font-size: 12px;
    margin-bottom: 30px;
  }

  .website-development img {
    max-width: 100%;
    margin-top: 3px;
    margin-bottom: 50px ;
  }

  .website-development ul li {
    margin-bottom: 5px;
  }

  .website-development .contact-us h2:last-of-type {
    font-size: 18px;
    margin-top: 15px;
  }

  .website-development .contact-us p {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
