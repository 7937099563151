.services-container {
  padding: 0 2rem;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.services-title {
  margin-top: 0;
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2.1rem;
  text-align: center;
}

.services-title .highlight {
  color: #107678;
  font-size: 2.5rem;
}

.services-cards {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin-bottom: 1rem;
}

.service-card {
  background-color: #ffffff;
  border: 2px solid #e0e0e0;
  border-radius: 15px;
  box-shadow: 0 4px 8px #2e3d3d;
  padding: 1rem;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.service-icon {
  width: 180px;
  height: 180px;
  margin-bottom: 1rem;
  text-align: center;
}

.service-card-orange {
  background-color: #ffdd8e;
  color: #333;
}

.service-card-black {
  background-color: #333;
  color: #FFF;
}

.service-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0rem;
}

.service-description {
  font-size: 0.9rem;
  line-height: 1.6;
  text-align: left;
  display: inline;
}

.button-container {
  margin-top: 10px; 
}

.service-button {
  background-color: #fda521; 
  color: #fff; 
  border: none;
  padding: 5px 10px; 
  border-radius: 5px; 
  cursor: pointer; 
  font-size: 13px; 
  font-weight: bold; 
}

.service-button:hover{
  background-color: #00796b;
}

/* Container for View More button */
.view-more-container {
  text-align: center;
  margin-top: 40px;
}

/* View More Button Styling */
.view-more-button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #fda521;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  border: none;
}

.view-more-button:hover {
  background-color: #00796b;
  transform: scale(1.05);
}


@media (max-width: 768px) {
  .services-cards {
    flex-direction: column;
    align-items: center;
  }

  .services-title {
    font-size: 2.3rem;
  }

  .service-card {
    width: 55%;
    padding: 1.5rem;
    height: auto;
  }

  .service-title {
    font-size: 1.15rem;
  }

  .service-description {
    font-size: 0.95rem;
  }

  .service-icon {
    width: 140px;
    height: 140px;
    margin-bottom: 0.8rem;
  }
}

@media (max-width: 360px), (max-width: 412px)  {
  .services-container {
    padding: 1rem;
  }

  .services-title {
    margin-top: 0;
    font-size: 2.5rem ;
    margin-bottom: 1rem;
  }

  .services-cards {
    gap: 1rem;
    padding: 0 0.5rem;
    margin-bottom: 0;
  }

  .service-card {
    width: 100%;
    padding: 1rem;
  }

  .service-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 0.5rem;
  }

  .service-title {
    font-size: 1.5rem !;
  }

  .service-description {
    font-size: 0.75rem;
  }
}
