.pricing-page {
    width: 80%;
    margin: 70px auto;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
}

.pricing-page h1 {
    text-align: center;
    margin-top: 40px;
    font-size: 35px;
    font-weight: bold;
    color: #2980b9;
}

.pricing-section h2 {
    color: #2980b9;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 15px;
    text-align: left ;
}

.pricing-section p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 15px;
    color: rgb(81, 71, 71);
    text-align: left ;
}

.pricing-section p strong {
    font-weight: bold;
    color: black ;
}

.pricing-section ul, ol {
    margin-left: 20px;
    font-size: 16px;
    color: rgb(81, 71, 71) ;
}

.pricing-section ul li, ol li {
    margin-bottom: 8px;
}

.pricing-section img {
    max-width: 70%;
    height: auto;
    display: block;
    margin: 15px auto;
    border-radius: 8px;
}

@media (max-width: 360px), (max-width: 412px) {
    .pricing-page {
        padding: 8px;
        width: 90%;
    }

    .pricing-page h1 {
        font-size: 30px;
    }

    .pricing-section h2 {
        font-size: 18px !important;
    }

    .pricing-section p,
    .pricing-section ul {
        font-size: 14px;
        text-align: left ;
        line-height: 1.6;
    }

    .pricing-section img {
        max-width: 60%;
    }
}

/* @media (max-width: 480px) {
    .pricing-page {
        padding: 8px;
        width: 90%;
    }

    .pricing-page h1 {
        font-size: 26px;
    }

    .pricing-section h2 {
        font-size: 20px;
    }

    .pricing-section p,
    .pricing-section ul {
        font-size: 14px;
        line-height: 1.6;
    }

    .pricing-section img {
        max-width: 65%;
    }
} */

@media (max-width: 768px) {
    .pricing-page {
        padding: 10px;
        width: 80%;
    }

    .pricing-page h1 {
        font-size: 28px;
    }

    .pricing-section h2 {
        font-size: 22px;
    }

    .pricing-section p,
    .pricing-section ul {
        font-size: 15px;
    }

    .pricing-section img {
        max-width: 75%;
    }
}
