.blog-section {
    text-align: center;
    padding: 40px;
    background-color: white;
}

.blog-title {
    font-size: 2.5rem;
  color: #0a7273;
}

.blog-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.blog-card {
    width: 300px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
}

.blog-card:hover {
    transform: translateY(-5px);
}

.blog-image-container {
    position: relative;
    width: 100%;
    height: 180px;
}

.blog-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-content {
    padding: 15px;
    text-align: left;
}

.blog-heading {
    font-size: 16px;
    line-height: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #444;
}

.blog-preview {
    font-size: 14px;
    color: #666;
    line-height: 1.4;
}

.blog-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 12px;
    color: gray;
}

/* Button Styling */
.blog-button-container {
    margin-top: 20px;
}

.blog-button {
    background-color: white ;
    color: teal;
    padding: 10px 20px;
    font-size: 16px;
    border: 2px solid teal;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.blog-button:hover {
    background-color: teal;
    color: white;
}
/* ✅ Responsive Design for Mobile (412px) and Tablet (768px) */
@media (max-width: 768px) {
    .blog-title {
        font-size: 2rem; /* Smaller title */
    }

    .blog-container {
        gap: 10px; /* Reduce gap between blog cards */
    }

    .blog-card {
        width: 40%; /* Make the blog cards take more space */
    }

    .blog-heading {
        font-size: 14px;
    }

    .blog-preview {
        font-size: 12px;
    }
}

@media (max-width: 412px) {
    .blog-section {
        padding: 0px; /* Reduce padding */
    }

    .blog-title {
        font-size: 1.8rem; /* Even smaller title */
    }

    .blog-container {
        gap: 5px; /* Minimize space */
    }

    .blog-card {
        width: 100%; /* Full width */
        padding: 10px;
    }

    .blog-heading {
        font-size: 13px;
    }

    .blog-preview {
        font-size: 11px;
    }

    .blog-button {
        font-size: 14px;
        padding: 8px 16px;
    }
}