.seo-services-container {
  width: 80%;
  margin: 70px auto 0 auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.seo-services-container h1 {
  text-align: center;
  margin-top: 40px;
  font-size: 35px;
  font-weight: bold;
  color: #2980b9;
}

.seo-services-container h2 {
  color: #2980b9;
  margin-top: 20px;
  font-size: 20px;
  margin-bottom: 15px;
  text-align: left ;
}

.seo-services-container p {
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: black ;
  text-align: left ;
}

.seo-services-container ul {
  margin-left: 20px;
  font-size: 14px;
  margin-bottom: 40px ;
}

.seo-services-container img {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 30px auto;
  border-radius: 8px;
}

.seo-services-container ul li {
  margin-bottom: 8px;
}

.seo-services-container h3 > span {
  font-size: 20px;
  margin-right: 8px;
}

.seo-services-container .last h2 {
  text-align: center !important;
  font-size: 24px;
  font-weight: bold;
  margin-top: 40px;
  color: #2980b9;
}

.seo-services-container .last p {
  text-align: center !important;
  font-size: 16px;
}

.seo-services-container .last p:last-of-type {
  text-align: center ;
  font-size: 16px;
  color: #2980b9;
}

.last {
  text-align: center; /* Centers the text and the button */
}

.quotation-button {
  display: inline-block;
  margin-top: 20px;
  padding: 12px 20px;
  background-color: #FDA521; 
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.quotation-button:hover {
  background-color: #00796b;
  transform: scale(1.05);
}

@media (max-width: 360px), (max-width: 412px) {
  .seo-services-container {
    padding: 8px;
    margin: 70px auto 0 auto;
    width: 90%;
  }

  .seo-services-container h1 {
    font-size: 30px !important;
  }

  .seo-services-container h2 {
    font-size: 20px !important;
  }

  .seo-services-container p, .seo-services-container ul {
    font-size: 14px ;
  }

  .seo-services-container img {
    max-width: 90% ;
    margin-bottom: 0 ;
    max-width: 60%;
  }

  .seo-services-container ul {
    margin-left: 0 ;
  }

  .seo-services-container button, .seo-services-container a {
    font-size: 14px;
    padding: 6px 12px;
  }
}

/* @media (max-width: 480px) {
  .seo-services-container {
    padding: 8px;
    margin: 70px auto 0 auto;
    width: 90%;
  }

  .seo-services-container h1 {
    font-size: 30px !important;
  }

  .seo-services-container h2 {
    font-size: 20px !important;
  }

  .seo-services-container h3 {
    font-size: 15px;
  }

  .seo-services-container p, .seo-services-container ul {
    font-size: 14px !important;
  }

  .seo-services-container img {
    max-width: 90% !important;
    margin-bottom: 0 !important;
    max-width: 65%;
  }

  .seo-services-container ul {
    margin-left: 0 !important;
  }

  .seo-services-container button, .seo-services-container a {
    font-size: 14px;
    padding: 6px 12px;
  }
} */

@media (max-width: 768px) {
  .seo-services-container {
    padding: 10px;
    width: 80%;
  }

  .seo-services-container h1 {
    font-size: 35px ;
  }

  .seo-services-container h2 {
    font-size: 25px ;
  }

  .seo-services-container h3 {
    font-size: 16px;
  }

  .seo-services-container p, .seo-services-container ul {
    font-size: 15px;
  }

  .seo-services-container img {
    max-width: 90% !important;
    margin-bottom: 0 ;
    max-width: 75%;
  }

  .seo-services-container ul {
    margin-left: 20px;
  }

  .seo-services-container button, .seo-services-container a {
    font-size: 16px;
    padding: 8px 16px;
  }
}
